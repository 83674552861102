import { FunctionComponent, useContext, useEffect, useMemo, useRef, useState } from "react";
import { PageContent } from "../PageContent";
import { LoveLanguageQuestions } from "../LoveLanguage/LoveLanguageQuestions";
import { Card, IntroPage } from "../LoveLanguagePage";
import { Typography } from "@mui/material";
import { Button } from "../../components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../../context/ProfileContext";
const pages = require("./pages.json");

type QuestionnaireAnswer = {
    [key: string]: number;
};

export const attachmentResults = {
    anxious: "You might have an anxious attachment style, indicating a strong desire for closeness but also a fear of abandonment.",
    secure: "You might have a secure attachment style, indicating a healthy balance between independence and intimacy in relationships.",
    avoidant: "You might have an avoidant attachment style, valuing independence and self-sufficiency while being somewhat uncomfortable with emotional closeness.",
    mixed: "Your attachment style might be a combination or variation of the traditional styles."
};

export const attachmentValues = {
    anxious: "Anxious Attachment Style",
    secure: "Secure Attachment Style",
    avoidant: "Avoidant Attachment Style",
    mixed: "Mixed Attachment Style"
};

const Answers = ({ answers }: { answers: Map<string, number> }) => {
    const {addAttachmentStyleResults, attachmentStyle} = useContext(ProfileContext);
    const navigation = useNavigate();

    useEffect(() => {
        if (answers) {
            addAttachmentStyleResults(answers);
        }
    }, []);

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
            <Typography flex={1} variant={'h4'} textAlign={'center'}>Attachment Style Results</Typography>
            {attachmentStyle != null && <>
            <Typography flex={1} textAlign={'center'}>{attachmentValues[attachmentStyle]}</Typography>
                <Typography flex={1} textAlign={'center'}>
                    {attachmentResults[attachmentStyle]}
                </Typography>
            </>
            }
            <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center', display: 'flex' }}>
                <Button onClick={() => {
                    navigation('/profile');
                }}>Done</Button>
            </div>
        </div>
    )
}
export const AttachmentStyle: FunctionComponent = () => {
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    const [answers] = useState<Map<string, number>>(new Map());
    const [activeStep, setActiveStep] = useState(0);
    const { pages: questionnaire, options } = pages;
    const partnerPages = useMemo(() => questionnaire.sort(() => Math.random()), []);
    const [values, setValues] = useState<number[]>(new Array(partnerPages.length).fill(50));
    const allValues = useRef<QuestionnaireAnswer[]>([]);

    const onNext = () => {
        if (answers.get(partnerPages[activeStep].label) != null) {
            answers.set(partnerPages[activeStep].label, values[activeStep] + answers.get(partnerPages[activeStep].label)!);
        } else {
            answers.set(partnerPages[activeStep].label, values[activeStep]);
        }
        if (activeStep === partnerPages.length - 1) {
            setFinished(true);
            setStarted(false);
            return
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    const setAttachmentStyle = (index: number) => {
        const page = partnerPages[index];
        return (val: number) => {
            if (!allValues.current[index]) {
                allValues.current = [
                    ...allValues.current.filter(a => Object.keys(a)[0] !== page.title),
                    { [page.title]: Math.round(val * 25) } as QuestionnaireAnswer,
                ];
            }
            if (page.inverted) {
                answers.set(index.toString(), 5 - val);
            } else {
                answers.set(index.toString(), val + 1);
            }
            setValues([...values.slice(0, index), val, ...values.slice(index + 1)]);
        };
    };

    return (
        <PageContent>
            <Card>
                {started ? <LoveLanguageQuestions
                        value={values[activeStep]}
                        activeStep={activeStep}
                        total={partnerPages.length}
                        options={options}
                        page={partnerPages[activeStep]}
                        setValue={setAttachmentStyle(activeStep)}
                        onNext={onNext} /> :
                    !finished ? <IntroPage title={'Attachment Style Test'}
                                           content={'Here\'s a simple questionnaire to help you determine your attachment style in a relationship. Keep in mind that attachment styles can be more complex and nuanced than this questionnaire.\n\nFor each question, choose the response that best describes your feelings and behaviors in romantic relationships. Answer honestly and instinctively.'}
                                           setStarted={setStarted} /> : <Answers answers={answers} />}
            </Card>
        </PageContent>
    )
}
