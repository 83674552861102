import { AuthorizationContext } from "../context/AuthorizationProvider";
import React, { useContext, useMemo, useState } from "react";
import { PageContent } from "./PageContent";
import { Button } from "../components/Buttons/Button";
import { PieChart } from "@mui/x-charts";
import { useNavigate } from "react-router-dom";
import { ProfileContext } from "../context/ProfileContext";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { attachmentResults, attachmentValues } from "./AttachmentStyle";
import * as profileLottie from "./profile.json";
import { Player } from "@lottiefiles/react-lottie-player";
import { updateProfile } from "../fetch/user";

export type LoveLanguages = 'acts-of-service' | 'physical-touch' | 'words-of-affirmation' | 'gifts' | 'quality-time';
export type AttachmentStyles = 'anxious' | 'secure' | 'avoidant' | 'mixed';

const loveLanguageLabels = {
    'physical-touch': 'Physical Touch',
    'acts-of-service': 'Acts of Service',
    'words-of-affirmation': 'Words of Affirmation',
    'quality-time': 'Quality Time',
    'gifts': 'Receiving Gifts',
};

export const ProfilePage = () => {
    const { profile } = useContext(AuthorizationContext);
    const [name, setName] = useState(profile?.firstName);
    console.assert(profile, 'Profile is not set');
    const { loveLanguageResults: loveLanguage, attachmentStyle } = useContext(ProfileContext);

    const primaryLoveLanguage: LoveLanguages | null | undefined = useMemo(() => {
        if (loveLanguage != null) {
            const values = Array.from(loveLanguage.values());
            const max = Math.max(...values);
            return Array.from(loveLanguage.entries()).find(([key, value]) => value === max)?.[0];
        } else {
            return null;
        }
    }, [loveLanguage]);

    const navigate = useNavigate();

    return (
        <PageContent>
            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'center', padding: '0 40px', gap: 10 }}>
                <div style={{ display: 'flex' }}>
                    <Typography variant={'h4'} marginBottom={4} style={{ color: '#575757' }}>Welcome, {profile?.firstName}!</Typography>
                </div>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Typography variant={'h5'} marginBottom={4}>Your Love Language</Typography>
                        {loveLanguage && primaryLoveLanguage && <div style={{ marginTop: '20px', marginBottom: '40px' }}>
                            Your primary love language is {loveLanguageLabels ? <span style={{fontWeight: '600'}}>{loveLanguageLabels[primaryLoveLanguage]}</span> : ''} Understanding your love
                            language can greatly enhance your relationships by helping you and your loved ones communicate and connect more
                            effectively.
                        </div>}
                        <PieChart
                            title={'Love Language'}
                            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                            slotProps={{
                                legend: {
                                    hidden: false,
                                    direction: 'row',
                                    position: { vertical: 'top', horizontal: 'middle' },
                                    padding: 10,
                                },
                            }}
                            series={[
                                {
                                    innerRadius: 14,
                                    paddingAngle: 3,
                                    data: [
                                        { id: 0, value: loveLanguage?.get('physical-touch') ?? 20, label: 'Physical Touch' },
                                        { id: 1, value: loveLanguage?.get('acts-of-service') ?? 20, label: 'Acts of Service' },
                                        { id: 2, value: loveLanguage?.get('words-of-affirmation') ?? 20, label: 'Words of Affirmation' },
                                        { id: 3, value: loveLanguage?.get('quality-time') ?? 20, label: 'Quality Time' },
                                        { id: 4, value: loveLanguage?.get('gifts') ?? 20, label: 'Receiving Gifts' },
                                    ],
                                    cx: 160,
                                    cy: 250,
                                },
                            ]}
                            width={320}
                            height={420}
                        />
                        <div style={{ marginTop: 40 }}>
                            <Button onClick={() => {
                                navigate('/love-language')
                            }}>Find out your love language</Button>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant={'h5'} marginBottom={4}>Your Attachment Style</Typography>
                        {attachmentStyle && <>
                            <Typography variant={'button'}>{attachmentValues[attachmentStyle]}</Typography>
                            <Typography marginTop={2}>{attachmentResults[attachmentStyle]}</Typography>
                        </>}
                        <Typography marginTop={2} marginBottom={5}>
                            Understanding attachment styles improves relationship dynamics and emotional connections.
                        </Typography>
                        <Button onClick={() => {
                            navigate('/attachment-style')
                        }}>Find out your Attachment Style</Button>
                    </Grid>
                </Grid>
            </div>
        </PageContent>
    )
}
