import { PageContent } from "./PageContent";
import { Typography } from "@mui/material";
import { Button } from "../components/Buttons/Button";
import { FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { LoveLanguageQuestions } from "./LoveLanguage/LoveLanguageQuestions";
import { ProfileContext } from "../context/ProfileContext";
import { PieChart } from "@mui/x-charts";
import { useNavigate } from "react-router-dom";
import { LoveLanguages } from "./ProfilePage";

const questions = require("./LoveLanguage/pages.json");

export const Card: FunctionComponent<PropsWithChildren> = ({ children }) => {
    return (
        <div style={{
            backgroundColor: '#f5f5f5',
            borderRadius: 15,
            alignItems: 'center',
            justifyContent: 'center',
            padding: 20,
            paddingLeft: 40,
            paddingRight: 40,
            display: 'flex',
            flexDirection: 'column',
            maxWidth: 520,
            flex: 1,
            marginBottom: 20,
            alignSelf: 'center'
        }}>
            {children}
        </div>
    )
}
export const IntroPage: FunctionComponent<{
    title: string;
    content: string;
    setStarted: (started: boolean) => void
}> = ({ setStarted, title, content }) => {
    return (
        <>
            <Typography flex={1} variant={'h5'} textAlign={'center'}>{title}</Typography>
            <Typography flex={1} marginTop={10} fontWeight={200}>
                {content}
            </Typography>
            <Typography flex={1} marginTop={10} fontSize={12}>
                Instructions: Read each statement and rate how true it is for you on a scale of 1 to 5, where 1 is "Not True at All" and 5 is
                "Extremely True." Be honest and go with your initial instincts. There are no right or wrong answers – just insights into what makes
                you feel most loved and appreciated.
            </Typography>
            <div style={{ marginTop: '40px', width: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}>
                <Button onClick={() => {
                    setStarted(true);
                }}>Start</Button>
            </div>
        </>
    )
}

const LoveLanguageResults: FunctionComponent<{ results: Map<LoveLanguages, number> }> = ({ results }) => {
    const { addLoveLanguageResults } = useContext(ProfileContext);
    useEffect(() => {
        if (results) {
            addLoveLanguageResults(results);
        }
    }, [results]);
    const navigation = useNavigate();

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'center' }}>
            <Typography flex={1} variant={'h4'} textAlign={'center'}>Love Language Results</Typography>
            <div style={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', height: 420 }}>
                <PieChart
                    margin={{ top: 10, bottom: 10, left: 60, right: 0 }}
                    slotProps={{
                        legend: {
                            hidden: true,
                            direction: 'row',
                            position: { vertical: 'top', horizontal: 'middle' },
                            padding: -10,
                        },
                    }}
                    series={[
                        {
                            data: [
                                { id: 0, value: results.get('physical-touch') ?? 20, label: 'Physical Touch' },
                                { id: 1, value: results.get('acts-of-service') ?? 20, label: 'Acts of Service' },
                                { id: 2, value: results.get('words-of-affirmation') ?? 20, label: 'Words of Affirmation' },
                                { id: 3, value: results.get('quality-time') ?? 20, label: 'Quality Time' },
                                { id: 4, value: results.get('gifts') ?? 20, label: 'Receiving Gifts' },
                            ],
                            innerRadius: 30,
                            outerRadius: 120,
                            paddingAngle: 5,
                            cornerRadius: 5,
                            startAngle: -180,
                            endAngle: 180,
                            cx: 150,
                            cy: 150,
                        },
                    ]}
                    height={420}
                    width={420}
                />
            </div>
            <Typography flex={1} textAlign={'center'}>
                By understanding your primary love language, you're one step closer to nurturing stronger, more meaningful relationships.
            </Typography>
            <div style={{ flex: 1, alignItems: 'flex-end', justifyContent: 'center', display: 'flex' }}>
                <Button onClick={() => {
                    navigation('/profile');
                }}>Done</Button>
            </div>
        </div>
    )
}

export const LoveLanguagePage = () => {
    const [started, setStarted] = useState(false);
    const [finished, setFinished] = useState(false);
    const { pages: questionnaire, options } = questions;
    const pages = useMemo(() => questionnaire.sort(() => Math.random()), []);
    const [activeStep, setActiveStep] = useState(0);
    const resultMap = useMemo(() => new Map<LoveLanguages, number>(), []);

    const [values, setValues] = useState<number[]>(new Array(pages.length).fill(50));

    const setValue = (index: number) => {
        return (value: number) => {
            setValues([...values.slice(0, index), value, ...values.slice(index + 1)]);
        }
    }
    const onNext = () => {
        if (resultMap.get(pages[activeStep].label as LoveLanguages) != null) {
            resultMap.set(pages[activeStep].label as LoveLanguages, values[activeStep] + resultMap.get(pages[activeStep].label as LoveLanguages)!);
        } else {
            resultMap.set(pages[activeStep].label as LoveLanguages, values[activeStep]);
        }
        if (activeStep === pages.length - 1) {
            setFinished(true);
            setStarted(false);
            //calculate love language results
            const woa = resultMap.get('words-of-affirmation');
            const gifts = resultMap.get('gifts');
            const acts = resultMap.get('acts-of-service');
            const qualityTime = resultMap.get('quality-time');
            const physicalTouch = resultMap.get('physical-touch');
            const total = woa! + gifts! + acts! + qualityTime! + physicalTouch!;
            resultMap.set('words-of-affirmation', Math.round((woa! / total) * 100));
            resultMap.set('gifts', Math.round((gifts! / total) * 100));
            resultMap.set('acts-of-service', Math.round((acts! / total) * 100));
            resultMap.set('quality-time', Math.round((qualityTime! / total) * 100));
            resultMap.set('physical-touch', Math.round((physicalTouch! / total) * 100));

            console.log(resultMap);
            return
        } else {
            setActiveStep(activeStep + 1);
        }
    }

    return (
        <PageContent>
            <Card>
                {started ? <LoveLanguageQuestions
                        value={values[activeStep]}
                        total={pages.length}
                        activeStep={activeStep}
                        options={options}
                        page={pages[activeStep]}
                        setValue={setValue(activeStep)}
                        onNext={onNext} /> :
                    !finished ? <IntroPage title={'Love Language Test'}
                                           content={'Understanding your love language can greatly enhance your relationships by helping you and your loved ones communicate and connect\n' +
                                               ' more effectively. This quick questionnaire will guide you in discovering your primary love language according to the 5 Love Languages'}

                                           setStarted={setStarted} /> : <LoveLanguageResults results={resultMap} />}
            </Card>
        </PageContent>
    )
}
