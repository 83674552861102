import { FunctionComponent, PropsWithChildren } from "react";

export const PageContent: FunctionComponent<PropsWithChildren> = ({children}) => {
    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: '#ecd5f6',
            width: '100%',
            height: '100%',
            paddingTop: '40px',
            paddingRight: '40px',
            paddingLeft: '40px',
            paddingBottom: '40px',
        }}>
            {children}
        </div>
    );
}
