import { createContext, FunctionComponent, PropsWithChildren, useContext, useState } from "react";
import { RelationshipPartner } from "../modals/Partner";

export const PartnerContext = createContext<{
    currentPartner: RelationshipPartner,
    setCurrentPartner: (partner: RelationshipPartner) => void
}>({
    currentPartner: {} as RelationshipPartner,
    setCurrentPartner: () => {}
})

export const PartnerContextProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
    const [currentPartner, setCurrentPartner] = useState<RelationshipPartner>({} as RelationshipPartner)

    return (
        <PartnerContext.Provider value={{currentPartner, setCurrentPartner}}>
            {children}
        </PartnerContext.Provider>
    )
}
