import { FunctionComponent, useContext, useEffect, useMemo, useState } from "react";
import { Button, css, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { AuthorizationContext } from "../../context/AuthorizationProvider";
import { StyleSheet } from "react-native";
import { getAllPartners } from "../../fetch/partners";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';


const useWindowDimensions = () => {
    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
};

export default useWindowDimensions;

const styles = StyleSheet.create({
    link: {
        cursor: 'pointer',
        color: '#fff',
        fontSize: 18,
        fontWeight: '600',
        textDecorationLine: 'none',
    }
})
export const Header: FunctionComponent<{setOpacity: (num: number) => void}> = ({setOpacity}) => {
    const { authenticated, profile, token, logout } = useContext(AuthorizationContext);
    const { width: screenWidth } = useWindowDimensions();

    const [width, setWidth] = useState(190);
    const [partners, setPartners] = useState<{ name: string; relationshipId: string }[]>([]);
    const [mobileView, setMobileView] = useState<boolean>(screenWidth < 670);

    useEffect(() => {
        if (screenWidth < 670) {
            setWidth(0);
            setMobileView(true);
        } else {
            setWidth(190);
            setMobileView(false)
        }
    }, [screenWidth]);

    useEffect(() => {
        if (authenticated && profile?.email && token) {
            getAllPartners(profile.email, token).then((relationships) => {
                setPartners(relationships);
            });
        }
    }, [authenticated, profile, token]);

    const goToHomePage = () => {
        if (authenticated) {
            window.location.href = '/profile';
        } else {
            window.location.href = '/'
        }
    }

    if (authenticated) {
        return (
            <Grid flexDirection={'row'} style={{
                backgroundColor: '#b58fc0',
                maxWidth: 170,
                width: width,
                paddingLeft: width === 0 ? 0 : 10,
                paddingRight: width === 0 ? 0 : 10,
                display: 'flex',
                flexDirection: 'column',
                position: 'fixed',
                zIndex: 1,
                height: '100vh',
                boxShadow: '2px 1px 10px #666666'
            }}>
                {mobileView &&
                    <Button style={{justifyContent: 'flex-end'}} onClick={() => {
                        if (width === 190) {
                            setOpacity(1);
                            setWidth(0);
                        } else {
                            setOpacity(0.2);
                            setWidth(190);
                        }
                    }}>
                        {width === 0 ? <MenuIcon /> : <CloseIcon />}
                    </Button>}
                <div style={{
                    zIndex: width === 0 ? -1 : 1,
                    display: width === 0 ? 'none' : 'flex',
                    flexDirection: 'column',
                }}>
                    <Grid item md={authenticated ? 1 : 1} style={{ cursor: 'pointer', display: 'flex' }}
                          alignContent='center'
                          onClick={goToHomePage}>
                        <img alt={'Logo'} width={'100%'}
                             style={{ maxWidth: 100, verticalAlign: 'bottom', alignSelf: 'center', justifySelf: 'center' }}
                             src={"/logo2.png"} />
                    </Grid>
                    <Grid style={{ gap: "20px", flexDirection: 'column', display: 'flex', padding: 10 }} item xs={2} sm={3} md={3} lg={4}
                          justifySelf={"flex-end"}>
                        <Link to={'/home'} style={styles.link}>Home</Link>
                        {partners.map((partner) => <Link key={partner.relationshipId} style={styles.link}
                                                         to={`/partner/${partner.relationshipId}`}>{partner.name}</Link>)}
                        <Link style={styles.link} to={'/add-partner'}>Add a Partner</Link>
                        <div style={styles.link} onClick={() => {
                            logout();
                        }}>
                            Log Out
                        </div>
                    </Grid>
                </div>
            </Grid>)
    } else
        return (
            <Grid lg={8}
                  paddingY={1}
                  zIndex={10} container
                  spacing={0}
                  alignSelf={'center'}
                  justifyContent={"center"}
                  paddingRight={2}
                  paddingLeft={1}>
                <Grid item md={1} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }} alignContent='center' onClick={goToHomePage}>
                    <img width={'100%'} style={{ maxWidth: 100, verticalAlign: 'bottom' }} src={"/logo2.png"} />
                </Grid>
                {width !== 0 && (
                    <>
                        {!authenticated && (
                            <Grid item xs={7} sm={7} md={7} lg={7} style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                <a style={{ fontSize: 20, fontWeight: '600', cursor: 'pointer' }} onClick={goToHomePage}>Scrapbook</a>
                            </Grid>
                        )}
                        <Grid style={{ gap: "10px", display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} item xs={2} sm={3} md={3}
                              lg={4}
                              justifySelf={"flex-end"}>
                            {!authenticated && (
                                <>
                                    <Link style={{ color: '#656565', textDecoration: 'none' }} to='/about'>About</Link>
                                    <Link style={{ color: '#656565', textDecoration: 'none', marginLeft: "10px" }} to='/login'>Login</Link>
                                </>
                            )}
                        </Grid>
                    </>)}
            </Grid>
        )
}
