import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import './App.css';
import { Box, createTheme, CssBaseline, Grid, ThemeProvider } from "@mui/material";
import { ThemeProvider as AmplifyThemeProvider } from '@aws-amplify/ui-react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { HomePage } from "./pages/HomePage";
import { AboutPage } from "./pages/AboutPage";
import useWindowDimensions, { Header } from "./components/Header";
import { Footer } from "./components/Footer";
import { ErrorPage } from "./pages/ErrorPage";
import { Privacy } from "./pages/Privacy";
import { Terms } from "./pages/Terms";
import { AddPartnerPage } from "./pages/AddPartnerPage";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { SignUpPage } from "./pages/SignUp";
import '@aws-amplify/ui-react/styles.css';
import { AuthorizationContext, AuthorizationProvider } from "./context/AuthorizationProvider";
import { Authorizer } from "./components/Auth/Authorizer";
import { AppHomePage } from "./pages/AppHomePage";
import { ProfilePage } from './pages/ProfilePage';
import { getAllPartners, getRelationship } from "./fetch/partners";
import { PartnerPage } from "./pages/PartnerPage";
import { LoveLanguagePage } from "./pages/LoveLanguagePage";
import { ProfileContextProvider } from "./context/ProfileContext";
import { AttachmentStyle } from "./pages/AttachmentStyle";
import { PartnerLoveLanguageQuestions } from "./pages/LoveLanguage/PartnerLoveLanguageQuestions";
import { PartnerLoveLanguagePage } from "./pages/PartnerLoveLanguagePage";
import { PartnerAttachmentStylePage } from './pages/PartnerAttachmentStylePage';
import { PartnerContextProvider } from "./context/PartnerContext";
import { AddDatePage } from "./pages/AddDatePage";

const defaultTheme = createTheme();

const theme = {
    name: 'my-theme',
    tokens: {
        colors: {
            font: {
                primary: { value: '#008080' },
                // ...
            },
        },
    },
};

const RouterProvider: FunctionComponent<{ authenticated: boolean; }> = ({ authenticated }) => {
    return (
        <Routes>
            <Route path={'/about'} element={<AboutPage />} />
            <Route path={'/privacy'} element={<Privacy />} />
            <Route path={'/terms'} element={<Terms />} />
            <Route path={'/auth'} element={<Authorizer />} />
            {!authenticated &&
                <>
                    <Route path={'/'} element={<HomePage />} />
                    <Route path={'/login'} element={<SignUpPage />} />
                </>
            }
            {authenticated && (
                <>
                    <Route path={'/'} element={<ProfilePage />} />
                    <Route path={'/partner/:id'} element={<PartnerPage />} />
                    <Route path={'/partner/:id/:partnerId/love-language'} element={<PartnerLoveLanguagePage />} />
                    <Route path={'/partner/:id/:partnerId/attachment-style'} element={<PartnerAttachmentStylePage />} />
                    <Route path={'/home'} element={<ProfilePage />} />
                    <Route path={'/profile'} element={<ProfilePage />} />
                    <Route path={'/love-language'} element={<LoveLanguagePage />} />
                    <Route path={'/attachment-style'} element={<AttachmentStyle />} />
                    <Route path={'partner/:id/dates'} element={<AddDatePage />} />
                </>
            )}
            <Route path={'/add-partner'} element={<AddPartnerPage />} />
            <Route path={'*'} element={<ErrorPage />} />
        </Routes>
    );
}

const AppContent = () => {
    const { authenticated } = useContext(AuthorizationContext);
    const { width: screenWidth } = useWindowDimensions();
    const [opacity, setOpacity] = useState(1);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: authenticated ? 'row' : 'column',
                minHeight: '100vh',
                minWidth: '90vw',
            }}
        >
            <CssBaseline />
            <Header setOpacity={setOpacity} />
            <Grid
                style={{
                    opacity: opacity,
                }}
                display={'flex'}
                direction={'column'}
                minHeight={'100vh'}
                width={'100%'}
                marginLeft={(!authenticated || screenWidth < 670) ? 0 : '170px'}
                flexDirection={'column'}>
                <RouterProvider authenticated={authenticated} />
                <Footer />
            </Grid>
        </Box>
    )
}

function App() {
    return (
        <BrowserRouter>
            <AuthorizationProvider>
                <ProfileContextProvider>
                    <PartnerContextProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <AmplifyThemeProvider theme={theme}>
                                <ThemeProvider theme={defaultTheme}>
                                    <AppContent />
                                </ThemeProvider>
                            </AmplifyThemeProvider>
                        </LocalizationProvider>
                    </PartnerContextProvider>
                </ProfileContextProvider>
            </AuthorizationProvider>
        </BrowserRouter>

    );
}

export default App;
